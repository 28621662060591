.container__heading {
  border-left: 4px solid #0579E6;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  padding-left: 16px
}

.input {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0
}

.input__item+.input__item {
  margin-top: 24px
}

.input__label {
  font-size: 14px;
  font-weight: bold
}

.input__label span {
  color: #757575;
  font-weight: normal;
  margin-left: 8px
}

.input .optional {
  font-weight: normal
}

.input .required {
  color: #004E98;
  font-weight: bold
}

.input__textbox {
  border: 1px solid #CCC;
  color: #444;
  display: block;
  margin-top: 8px;
  padding: 8px;
  height: 32px;
  width: 488px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.input__textbox:focus {
  box-shadow: 0 0 4px #004E98
}

.input__textbox::-webkit-input-placeholder {
  color: #A9A9A9;
  font-size: 12px
}

.input__textbox:-ms-input-placeholder {
  color: #A9A9A9;
  font-size: 12px
}

.input__textbox::-ms-input-placeholder {
  color: #A9A9A9;
  font-size: 12px
}

.input__textbox::placeholder {
  color: #A9A9A9;
  font-size: 12px
}

.input__textbox-notice {
  font-size:10px;
  margin:8px 0 0
}

.input__checkbox {
  position: relative;
  margin-right: 5px;
}

.input__checkbox input {
  display: none
}

.input__checkbox .input__check {
  background-color: #fff;
  border: 2px solid #0579E6;
  border-radius: 4px;
  display: inline-block;
  height: 15px;
  width: 15px;
  position: relative;
  vertical-align: bottom
}

.input__checkbox .input__check::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 4px;
  display: inline-block;
  margin-top: -4px;
  width: 5px;
  height: 11px
}

.input__checkbox .input__check:hover {
  cursor: pointer
}

.input__checkbox input[type='checkbox']:checked+.input__check {
  background-color: #0579E6
}

.input__checkbox input[type='checkbox']:checked+.input__check:after {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg)
}

.input__btn {
  background-color: #0579E6;
  border: 0;
  border-radius: 4px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: .15em;
  line-height: 2;
  padding: 8px;
  width: 124px;
  margin-top: 16px;
  box-sizing: border-box
}

.input__btn:hover {
  background-color: #439EF3;
  cursor: pointer
}

.input__btn:focus {
  outline: none
}

.input__btn:active {
  background-color: #0463BB;
}

.input__btn:disabled {
  background-color: #A4CEF5;
}

.input__btn:disabled:hover {
  cursor: default
}

.input__btn-notice {
  font-size:10px;
  margin:24px 0 0
}
