.uploader__box {
  background-color: #F8F9FA;
  border: 4px dashed #A4CEF5;
  border-radius: 12px;
  text-align: center;
  padding: 80px 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center
}

.uploader__box:focus {
  outline: none
}

.uploader__guide {
  font-size: 20px;
  font-weight: bold;
  margin: 0
}

.uploader__guide span {
  display: block;
  font-size: 14px;
  font-weight: normal;
  margin-top: 16px
}

.uploader__btn {
  background-color: #0579E6;
  border-radius: 4px;
  color: #FFF;
  font-size: 13px;
  font-weight: bold;
  line-height: 2;
  margin-top: 16px;
  padding: 8px;
  width: 264px;
  box-sizing: border-box
}

.uploader__btn input {
  display: none
}

.uploader__btn:hover {
  background-color: #439EF3;
  cursor: pointer
}

.uploader__btn:focus {
  outline: none
}

.uploader__btn:active {
  background-color: #0463BB;
}

.uploader__caution {
  color: #EF363F;
  font-size: 14px;
  font-weight: bold;
  margin: 32px 0 0;
  line-height: 1.6
}

.uploader__notice {
  font-size: 10px;
  margin: 10px 0 0;
  line-height: 1.6
}

.uploader__list {
  margin-top: 16px;
  padding: 0
}
