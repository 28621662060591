.uploader__item {
  background-color: #E0EEFB;
  font-size: 14px;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  padding: 0 16px
}

.uploader__item+.uploader__item {
  margin-top: 8px
}

.uploader__item .icon-trash {
  margin: 3px 0 -3px
}

.uploader__item .icon-trash img{
  height: 14px;
}

.uploader__item .remove-btn:hover {
  cursor: pointer
}

.uploader__file {
  color: #757575;
  margin: 0;
  height: 32px
}

.uploader__file .uploader__filename, .uploader__file .uploader__volume {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.uploader__file .uploader__filename {
  max-width: 530px
}

.uploader__file .uploader__volume {
  max-width: 90px;
  margin-left: 4px
}
