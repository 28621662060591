.message {
  position: fixed;
  bottom: 0;
  margin-bottom: -48px;
  background-color: #333B46;
  color: #fff;
  text-align: center;
  width: 100%
}

.message__text {
  font-size: 14px;
  line-height: 48px;
  position: relative;
  margin: 0
}

.message__text .icon-check {
  background-color: #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  top: -1px;
  margin-right: 8px;
  vertical-align: middle
}

.message__text .icon-check:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 5px;
  display: inline-block;
  width: 4px;
  height: 6px;
  border-right: 2px solid #333B46;
  border-bottom: 2px solid #333B46;
  transform: rotate(45deg)
}

.fade-enter {
  margin-bottom: -48px;
}
.fade-enter-active {
  margin-bottom: 0px;
  transition: margin-bottom 500ms;
}

.fade-exit {
  margin-bottom: -48px;
  transition: margin-bottom 500ms;
}
