body {
  margin: 0;
  font-family: Meiryo, "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif;
  color: #222;
  letter-spacing: 0.75px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-wrap {
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 32px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
