.about {
  background-color: #F8F9FA;
  box-sizing: border-box;
  font-size: 12px;
  padding: 0 48px;
  line-height: 48px;
  box-shadow: -0px 4px 6px rgb(0 0 0 / .05) inset;
}

.about__icon-help {
  background-color: #004E98;
  border-radius: 50%;
  color: #FFF;
  display: inline-block;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: bold;
  height: 18px;
  width: 18px;
  text-align: center;
  line-height: 18px;
  text-decoration: none;
}

.about__icon-help:hover {
  cursor: pointer;
}
