.modal {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.modal__box {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 240px;
  width: 624px;
  padding-bottom: 25px;
}
.modal__box.modal__about {
  height: 345px;
}
.modal .modal__btn-close {
  color: #004e98;
  width: 21px;
  height: 21px;
  position: absolute;
  right: 16px;
  top: 16px;
}
.modal .modal__btn-close::before {
  content: "";
  position: absolute;
  top: 10px;
  width: 21px;
  height: 1px;
  background-color: currentColor;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.modal .modal__btn-close::after {
  content: "";
  position: absolute;
  top: 10px;
  width: 21px;
  height: 1px;
  background-color: currentColor;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.modal .modal__btn-close:hover {
  cursor: pointer;
}
.modal__heading {
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0 0 24px;
}
.modal__message {
  font-weight: bold;
  text-align: center;
  width: 450px;
  margin: 48px auto 0;
}
.modal__message .message__heading {
  font-size: 20px;
  margin: 16px 0;
  color: #444444;
}
.modal__message .message__heading .icon-attention {
  font-size: 12px;
  margin-right: 16px;
  vertical-align: middle;
  width: 40px;
}
.modal__message .message__text {
  font-size: 12px;
  line-height: 1.5;
  margin: 0px;
  color: #757575;
}
.modal__message .message__text .message__list {
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.modal__message .message__text p,ul {
  margin: 0px;
}
.modal .modal__btn {
  text-align: center;
}
.modal .modal__btn .input__btn {
  border-radius: 4px;
  font-weight: normal;
  padding: 4px;
  width: 124px;
  margin-top: 24px;
  box-sizing: border-box;
}
.input__btn + .input__btn {
  margin-left: 24px;
}
.modal .modal__btn .input__btn:hover {
  cursor: pointer;
}
.modal .modal__btn .input__btn.ok {
  background-color: #0579E6;
  color: #fff;
}
.modal .modal__btn .input__btn.ok:hover {
  background-color: #439EF3;
}
.modal .modal__btn .input__btn.ok:focus {
  outline: none
}
.modal .modal__btn .input__btn.ok:active {
  background-color: #0463BB;
}
.modal .modal__btn .input__btn.cancel {
  background-color: #fff;
  border: 1px solid #ccc;
  color: inherit;
}
.modal .modal__link-howto {
    background-color: #F1F3F4;
    line-height: 32px;
    margin-top: 32px;
    text-align: center;
}
.modal .modal__link-howto a {
    color: #004E98;
    font-size: 13px;
    line-height: 46px;
}
